import React from 'react';
// import React, { useState, useEffect, FormEvent } from 'react';
// import axios from 'axios';

// interface Item {
// id: string;
// name: string;
// description: string;
// }

const App: React.FC = () => {
  // const [items, setItems] = useState<Item[]>([]);
  // const [newItem, setNewItem] = useState<{ name: string; description: string }>({
  //   name: '',
  //   description: '',
  // });

  // const [editingItem, setEditingItem] = useState<Item | null>(null);

  // useEffect(() => {
  //   fetchItems();
  // }, []);
  // const API_URL = "http://localhost:8080"
  // const fetchItems = async () => {
  //   try {
  //     const response = await axios.get(`${API_URL}/api/items`);
  //     setItems(response.data);
  //   } catch (error) {
  //     console.error('Error fetching items:', error);
  //   }
  // };

  // const handleAddItem = async (e: FormEvent) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(`${API_URL}/api/items`, newItem);
  //     setItems([...items, response.data]);
  //     setNewItem({ name: '', description: '' });
  //   } catch (error) {
  //     console.error('Error adding item:', error);
  //   }
  // };

  // const handleEditItem = (item: Item) => {
  //   setEditingItem(item);
  // };

  // const handleUpdateItem = async (e: FormEvent) => {
  //   e.preventDefault();
  //   if (editingItem) {
  //     try {
  //       const response = await axios.put(`${API_URL}/api/items/${editingItem.id}`, editingItem);
  //       setItems(items.map(item => (item.id === editingItem.id ? response.data : item)));
  //       setEditingItem(null);
  //     } catch (error) {
  //       console.error('Error updating item:', error);
  //     }
  //   }
  // };

  // const handleDeleteItem = async (id: string) => {
  //   try {
  //     await axios.delete(`${API_URL}/api/items/${id}`);
  //     setItems(items.filter(item => item.id !== id));
  //   } catch (error) {
  //     console.error('Error deleting item:', error);
  //   }
  // };

  // return (
  //   <div className="App">
  //     <h1>CRUD App</h1>

  //     {/* Add Item Form */}
  //     <form onSubmit={handleAddItem}>
  //       <input
  //         type="text"
  //         placeholder="Name"
  //         value={newItem.name}
  //         onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
  //         required
  //       />
  //       <input
  //         type="text"
  //         placeholder="Description"
  //         value={newItem.description}
  //         onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
  //         required
  //       />
  //       <button type="submit">Add Item</button>
  //     </form>

  //     {/* Edit Item Form */}
  //     {editingItem && (
  //       <form onSubmit={handleUpdateItem}>
  //         <input
  //           type="text"
  //           value={editingItem.name}
  //           onChange={(e) => setEditingItem({ ...editingItem, name: e.target.value })}
  //           required
  //         />
  //         <input
  //           type="text"
  //           value={editingItem.description}
  //           onChange={(e) => setEditingItem({ ...editingItem, description: e.target.value })}
  //           required
  //         />
  //         <button type="submit">Update Item</button>
  //       </form>
  //     )}

  //     {/* Items List */}
  //     <ul>
  //       {items.map((item) => (
  //         <li key={item.id}>
  //           <strong>{item.name}</strong>: {item.description}
  //           <button onClick={() => handleEditItem(item)}>Edit</button>
  //           <button onClick={() => handleDeleteItem(item.id)}>Delete</button>
  //         </li>
  //       ))}
  //     </ul>
  //   </div>
  // );
  return <h1>Hello worlddddddddddddddddddddddddd</h1>
};

export default App;
